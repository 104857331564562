define("mgw/services/powerbiservice", ["exports", "mgw/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    clipboardservice: Ember.inject.service(),
    generatePowerBICodeToGetAccessToken: function generatePowerBICodeToGetAccessToken(refreshToken) {
      // If onpremise, just include and use the current access token,
      // as this lasts forever - and refresh tokens don't work onprem anyway
      if (refreshToken == 'onpremise') {
        return "\n        AccessToken = \"".concat(this.get('session.data.authenticated.idToken'), "\",\n      ");
      }

      var tokenRefreshUrl = window.location.origin + _environment.default['ember-simple-auth-token'].serverTokenRefreshEndpoint;

      if (!refreshToken) {
        alert('You need to be logged in with "Keep me logged in" checked to export to Power BI');
        throw new Error();
      }

      return "\n      RefreshToken = \"".concat(refreshToken, "\",\n      TokenRefreshUrl = \"").concat(tokenRefreshUrl, "\",\n      Payload = \"{\"\"refreshToken\"\": \"\"\" & RefreshToken & \"\"\", \"\"token\"\": \"\"").concat(this.get('session.data.authenticated.idToken'), "\"\"}\",\n      PayloadBinary = Text.ToBinary(Payload),\n      TokenResponse = Json.Document(Web.Contents(TokenRefreshUrl,\n        [\n          Headers = [\n            #\"accept\" = \"application/json\",\n            #\"content-type\" = \"application/json\"\n          ],\n          Content = PayloadBinary\n        ]\n      )),\n      AccessToken = TokenResponse[idToken],\n    ");
    },
    copyQueryToClipboard: function copyQueryToClipboard(query, refreshToken) {
      var queryToExport;

      if (refreshToken) {
        var getTokenCode = this.generatePowerBICodeToGetAccessToken(refreshToken);
        queryToExport = query.replace('AccessToken = StreamBIM.GetAccessToken(),', getTokenCode);
      } else {
        queryToExport = query;
      }

      this.clipboardservice.copyTextToClipboard(queryToExport, this.intl.t('copiedToClipboard'));
    }
  });

  _exports.default = _default;
});