define("mgw/components/export-powerbi", ["exports", "mgw/components/popover-base", "mgw/config/environment"], function (_exports, _popoverBase, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _popoverBase.default.extend({
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    intl: Ember.inject.service(),
    eventservice: Ember.inject.service(),
    classNames: 'modal modal--centered',
    endDate: Ember.computed(function () {
      return window.moment().format("DD.MM.YYYY HH:mm");
    }),
    useConnector: true,
    password: null,
    error: null,
    disableSubmit: Ember.computed('password', 'useConnector', function () {
      return !this.get('useConnector') && !this.get('password');
    }),
    getRefreshToken: function getRefreshToken() {
      var userEmail = this.get('currentUser.user.email');
      var body = {
        username: userEmail,
        password: this.password
      };
      var accessToken = this.get('session.data.authenticated.idToken');
      var req = new XMLHttpRequest();

      try {
        req.open('POST', _environment.default['ember-simple-auth-token'].serverTokenEndpoint, false);
        req.setRequestHeader('Authorization', 'Bearer ' + accessToken);
        req.setRequestHeader('Content-Type', 'application/json');
        req.send(JSON.stringify(body));
        if (req.status != 200) throw req;
        return JSON.parse(req.response);
      } catch (err) {
        if (req === null || req === void 0 ? void 0 : req.response) {
          return {
            error: (req === null || req === void 0 ? void 0 : req.response) ? JSON.parse(req.response) : this.intl.t('error')
          };
        }
      }
    },
    actions: {
      submit: function submit() {
        var refreshToken = null;

        if (!this.get('useConnector')) {
          var res = this.getRefreshToken();

          if (res.error || !res.refreshToken) {
            this.set('error', res.error || this.intl.t('error'));
            return false;
          }

          refreshToken = res.refreshToken;
        }

        this.get('eventservice').sendEvent('exported-to-powerbi');
        this.get('delegate').send(this.get('callback'), refreshToken, this.get('options.context'));
        this.send('close');
        return false;
      },
      cancel: function cancel() {
        this.send('close');
        return false;
      }
    }
  });

  _exports.default = _default;
});