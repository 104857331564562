define("mgw/services/clipboardservice", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    popoverservice: Ember.inject.service(),
    copyTextToClipboard: function copyTextToClipboard(url, message) {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(url);
      } else {
        var textArea = document.createElement('textarea');
        textArea.value = url;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);
      }

      this.get('popoverservice').showMessage(message, 3000);
      return false;
    }
  });

  _exports.default = _default;
});